body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* atropos styles only here */
.atropos-frame {
  position: relative; /* Allows positioning of pseudo-elements */
  overflow: hidden; /* Hides overflowing content (optional) */
  /* Add padding or margin for spacing between frame and image */
}

.atropos-frame::before,
.atropos-frame::after {
  content: ""; /* Creates invisible pseudo-elements */
  position: absolute; /* Positions them relative to the image */
  top: 0;
  left: 0; /* Stretches them to cover the entire image area */
  width: 100%;
  height: 100%;
  /* Adjust these styles for the frame border and color */
  background-color: #ddd; /* Replace with your desired color */
  border: 2px solid #ccc; /* Replace with your border style and color */
  /* Optional: Add border-radius for rounded corners */
}

.atropos-frame::before {
  /* Style the top and left frame elements (optional) */
  /* You can use box-shadow for a subtle drop shadow effect */
}

.atropos-frame::after {
  /* Style the bottom and right frame elements (optional) */
}
