@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-width-container {
  width: 100%; /* Set the width to 100% */
}

.fade-in {
  opacity: 0;
  animation: fadeIn 15s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: -1;
  }
  to {
    opacity: 2;
  }
}

.drop-in {
  opacity: 0;
  transform: translateY(-50px);
  animation: dropIn 0.5s ease-in-out forwards;
}

@keyframes dropIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes blink {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 10; }
}

.animate-blink {
  animation: blink 10s infinite;
}

.animate-fade-in {
  animation: fade-in 1s;
}

.bg-image-url {
  background-image: url('/src/images/bg-fabric-2.png');
}



.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  z-index: 100;
}

.menubg-convex-image-url {
  background-image: url('/src/images/menu-bg-convex.png');
}

.menubg-image-url {
  background-image: url('/src/images/menu-bg.png');
}

.menubg-gold-url {
  background-image: url('/src/images/golden-bg.png');
}

.menubg-gold-bg-compressed-url {
  background-image: url('/src/images/bg_only/cylinderblue1.png');
}

.aqua1-url {	background-image: url('/src/images/bg_only/aqua1.png');	}
.aqua2-url {	background-image: url('/src/images/bg_only/aqua2.png');	}
.aqua3-url {	background-image: url('/src/images/bg_only/aqua3.png');	}
.bluegreen1-url {	background-image: url('/src/images/bg_only/bluegreen1.png');	}
.brightgreen1-url {	background-image: url('/src/images/bg_only/brightgreen1.png');	}
.cylinderaqua1-url {	background-image: url('/src/images/bg_only/cylinderaqua1.png');	}
.cylinderblue1-url {	background-image: url('/src/images/bg_only/cylinderblue1.png');	}
.cylinderblue2-url {	background-image: url('/src/images/bg_only/cylinderblue2.png');	}
.cylinderbluegreenmetal-url {	background-image: url('/src/images/bg_only/cylinderbluegreenmetal.png');	}
.cylinderdarkmetal-url {	background-image: url('/src/images/bg_only/cylinderdarkmetal.png');	}
.cylindergolden-url {	background-image: url('/src/images/bg_only/cylindergolden.png');	}
.cylindergreen1-url {	background-image: url('/src/images/bg_only/cylindergreen1.png');	}
.cylindergreen2-url {	background-image: url('/src/images/bg_only/cylindergreen2.png');	}
.cylindergreymetal-url {	background-image: url('/src/images/bg_only/cylindergreymetal.png');	}
.cylindershinyblue1-url {	background-image: url('/src/images/bg_only/cylindershinyblue1.png');	}
.cylinderyellow1-url {	background-image: url('/src/images/bg_only/cylinderyellow1.png');	}
.darkgrey1-url {	background-image: url('/src/images/bg_only/darkgrey1.png');	}
.darkpink1-url {	background-image: url('/src/images/bg_only/darkpink1.png');	}
.flataqua-url {	background-image: url('/src/images/bg_only/flataqua.png');	}
.flatblue-url {	background-image: url('/src/images/bg_only/flatblue.png');	}
.flatbrightgreen-url {	background-image: url('/src/images/bg_only/flatbrightgreen.png');	}
.flatbrightgreen2-url {	background-image: url('/src/images/bg_only/flatbrightgreen2.png');	}
.flatbrightred-url {	background-image: url('/src/images/bg_only/flatbrightred.png');	}
.flatdarkblue-url {	background-image: url('/src/images/bg_only/flatdarkblue.png');	}
.flatlightbeige-url {	background-image: url('/src/images/bg_only/flatlightbeige.png');	}
.flatlightgreen1-url {	background-image: url('/src/images/bg_only/flatlightgreen1.png');	}
.flatpink1-url {	background-image: url('/src/images/bg_only/flatpink1.png');	}
.flatred1-url {	background-image: url('/src/images/bg_only/flatred1.png');	}
.flatsaffron1-url {	background-image: url('/src/images/bg_only/flatsaffron1.png');	}
.flatsaffron2-url {	background-image: url('/src/images/bg_only/flatsaffron2.png');	}
.flatyellowblue-url {	background-image: url('/src/images/bg_only/flatyellowblue.png');	}
.gold1-url {	background-image: url('/src/images/bg_only/gold1.png');	}
.green1-url {	background-image: url('/src/images/bg_only/green1.png');	}
.green2-url {	background-image: url('/src/images/bg_only/green2.png');	}
.green3-url {	background-image: url('/src/images/bg_only/green3.png');	}
.green4-url {	background-image: url('/src/images/bg_only/green4.png');	}
.lightblue1-url {	background-image: url('/src/images/bg_only/lightblue1.png');	}
.liquidgreen1-url {	background-image: url('/src/images/bg_only/liquidgreen1.png');	}
.metalgrey1-url {	background-image: url('/src/images/bg_only/metalgrey1.png');	}
.shinygreen1-url {	background-image: url('/src/images/bg_only/shinygreen1.png');	}
.violet1-url {	background-image: url('/src/images/bg_only/violet1.png');	}
.red-glass-bg-url {	background-image: url('/src/images/bg_only/red-glass-bg.png');	}



.custom-shadow {
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
}

.menu-button {
  background-color: '#1DB954';
  color: '#abc';
  padding: '10px 20px';
  border-radius: '4px';
  font-size: '16px';
  cursor: 'pointer';
  border: 'none';
  outline: 'none';
  box-shadow: '0px 2px 4px rgba(0, 0, 0, 0.2)';
};

/* tailwind.css */
/* Light Mode Styles */
.bg-light-background {
  background-color: #fff; /* Light background color */
  color: #333; /* Light text color */
}

/* Dark Mode Styles */
.dark .bg-dark-background {
  background-color: #333; /* Dark background color */
  color: #fff; /* Dark text color */
}


.scrollable-container {
  overflow: auto;
}

.website-title {
  text-shadow: 1px 1px 5px #ccc;
}
 
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: inherit;
}
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
}
.content {
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem;
  /* background-color: #f2f2f2; */
  background-color: none;
}
@media only screen and (max-width: 600px) {
  .container {
	max-width: 100%;
  }
  .content {
	max-width: 100%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 800px) {
  .container {
	max-width: 95;
  }
  .content {
	max-width: 90%;
  }
}
@media only screen and (min-width: 801px) {
  .container {
	max-width: 85%;
  }
  .content {
	max-width: 99%;
  }
}

.loading-container {
  display: flex; /* Align items horizontally */
  flex-direction: column; /* Align items vertically */
  align-items: center;
  justify-content: center;
  min-height: 200px; /* Ensure minimum space for content */
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db; 
  border-radius: 50%;
  animation: spin linear infinite;
}

.loading-container p {
  font-size: 22px; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
