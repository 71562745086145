@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-list {
  display: flex; /* or display: grid; */
  list-style: none;
  padding: 5px;
  border-spacing: 10px;
  margin: 0;
}

.row-spacing {
  column-gap: 15px;
}

.project-images {
  height: 150px; 
  width: 266px;
}

.container {
  display: flex;
  flex-direction: column;
}

.container-compressed {
  width: 100%;
  max-width: 1200px; /* adjust as needed */
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}


.mobile-container {
  max-width: 90vw;
  margin: auto;
}

.div1 {
  background-color: blue;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.div2 {
  background-color: white; 
  color: black;
  padding: 10px;
  transition: height 0.3s ease-out;
}

.collapsed {
  height: 0;
  overflow: hidden;
}

.text-light {
  color: rgb(44, 47, 209);
  font-weight: 300;
}

.text-light-shadow {
  box-shadow: 0 4px 8px 0 rgba(59, 211, 92, 0.2);
}

.text-light-shadow-lg {
  text-shadow: 3px 2px 4px rgba(214, 55, 55, 0.2); /* Adjust the shadow values as needed */
}

#the ones below are for effects
div {
  transform: rotate(30deg);
}

iv {
  animation: shake 2s linear infinite;
}

/* tailwind.css */
/* Light Mode Styles */
.bg-light-background {
  background-color: #fff; /* Light background color */
  color: #333; /* Light text color */
}

/* Dark Mode Styles */
.dark .bg-dark-background {
  background-color: #333; /* Dark background color */
  color: #fff; /* Dark text color */
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0%; 
      /* max-width: clamp(320px, 95%, 1355px); */
      /* additional recommendation */
      /* margin: auto;  */
}

.scrollable-div {
  overflow-y: auto;
  height: auto;
}


/* Default styles for larger screens */
/* Add your default styles for header, nav, main, and footer as needed */

/* Media query for screens up to 768px (e.g., mobile phones) */
@media (max-width: 768px) {
  /* Styles for smaller screens (e.g., mobile phones) */
  /* Adjust font sizes, padding, and margins for mobile devices */
  /* Modify the layout for improved mobile viewing */

  /* Example: Reduce font size for headings on mobile screens */
  h1,
  h2,
  h3 {
    font-size: 1.25rem; /* Tailwind CSS class to set text to extra-large size */
  }

  /* Example: Reduce padding and margin for mobile screens */
  .content-container {
    padding: 0.5rem; /* Tailwind CSS class to set padding to 0.5rem */
  }

  /* Example: Hide or change the layout of certain elements on mobile screens */
  .mobile-only {
    display: none; /* Tailwind CSS class to hide an element */
  }
}

/* Media query for screens from 769px to 1024px (e.g., tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for tablets */
  /* Adjust font sizes, padding, and margins for tablet devices */
  /* Optimize the layout for improved tablet viewing */

  /* Example: Increase font size for headings on tablets */
  h1,
  h2,
  h3 {
    font-size: 1.5rem; /* Tailwind CSS class to set text to 2xl size */
  }

  /* Example: Increase padding and margin for tablets */
  .content-container {
    padding: 1rem; /* Tailwind CSS class to set padding to 1rem */
  }
}

/* Media query for screens from 1025px to 1440px (e.g., laptops) */
@media (min-width: 1025px) and (max-width: 1440px) {
  /* Styles for laptops */
  /* Adjust font sizes, padding, and margins for laptop screens */
  /* Optimize the layout for laptop viewing */

  /* Example: Increase font size for headings on laptops */
  h1,
  h2,
  h3 {
    font-size: 1.75rem; /* Tailwind CSS class to set text to 3xl size */
  }

  /* Example: Increase padding and margin for laptops */
  .content-container {
    padding: 1.5rem; /* Tailwind CSS class to set padding to 1.5rem */
  }
}

/* Media query for screens above 1440px (e.g., desktops) */
@media (min-width: 1441px) {
  /* Styles for larger screens (e.g., desktops) */
  /* Fine-tune font sizes, padding, and margins for desktop displays */
  /* Make the most of the available screen space */

  /* Example: Increase font size for headings on desktops */
  h1,
  h2,
  h3 {
    font-size: 2rem; /* Tailwind CSS class to set text to 4xl size */
  }

  /* Example: Increase padding and margin for desktops */
  .content-container {
    padding: 2rem; /* Tailwind CSS class to set padding to 2rem */
  }
}

html, body {
  overflow-y: auto;
  height: auto;

}

.transparent-text {
  color: rgba(225, 225, 225, 225);
  font-size: 1rem;
  font-weight: light;
}

/* Styling for the 3D effect */
.my-3d-div {
  width: auto;
  height: 22px;
  /* background-color: #3498db; */
  border-radius: 5px;
  font-size: small;
  box-shadow: 0 1px 2px rgba(1, 1, 1, 1.2);
  transition: transform 0.3s, box-shadow 0.5s;
}

.my-3d-div:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

/* General Styles */
.menu-item-mobile,
.menu-item-desktop {
  transition: background-color .3s ease;
}

.centered-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px; /* Light side padding on all devices */
}

@media (min-width: 768px) {
  .centered-content {
    padding-left: 5%; /* Larger left padding on wider screens */
  }
}