.spectrum-analyzer {
    width: 235px;
    height: 75px;
    background: #333;
    border: 0.1px solid #999;
    padding-top: 10px;
    border-radius: 0px;
    position: relative;
    text-align: center;
    text-wrap: nowrap;
    overflow: hidden;
  }
  
  .analyzer-display {
    position: absolute;
    top: 5%;
    left: 50%;
    width: 10%;
    height: 50%;
    transform: translate(-50%, -50%);
    /* border: 0.25px solid #ACA; */
    border-radius: 1px;
    /* background: #222; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .needle {
    width: 2px;
    height: 80px;
    background: red;
    position: absolute;
    bottom: 10%;
    transform-origin: bottom center;
    transition: transform 0.1s ease-out;
  }
  
  .scrolling-text {
    position: absolute;
    bottom: 5px;
    white-space: nowrap;
    font-size: 14px;
    color: #0f0;
    animation: scroll-text 60s linear infinite;
  }
  
  @keyframes scroll-text {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }  

  .canvas-background {
    background-image: url('../../../images/bg_only/spectrum_analyzer_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  

